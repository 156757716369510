import { Alert, AlertDescription, Box, Button, Stack } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Router from 'next/router';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { TermCategory } from '../../../generated/types';
import useIsMobile from '../../../hooks/useIsMobile';
import { trackEvent } from '../../../utils/analytics';
import { autocompleteResultToUrl, getSearchPath } from '../../../utils/helper';
import PlaceAutocomplete, {
  TransformedGeocoderFeature,
} from '../../Common/PlaceAutocomplete';
import BetterPlaceAutocomplete, {
  TransformedAutocompleteData,
} from '../../Common/BetterPlaceAutocomplete';
import RadiusSelect from '../../Common/RadiusSelect';

export interface HeroFormProps {
  category: TermCategory;
}

const HeroForm: React.FC<HeroFormProps> = ({ category }) => {
  const isMobile = useIsMobile();
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<TransformedAutocompleteData>();
  const [radius, setRadius] = useState<string>('30');
  const [query, setQuery] = useState('');
  const [showError, setShowError] = useState(false);

  const onSelectItem = (item: TransformedAutocompleteData) => {
    setSelectedItem(item);
    setShowError(false);
  };

  const onChangeRadius = (e: ChangeEvent<HTMLSelectElement>) => {
    setRadius(e.target.value);
  };

  const onChangeQuery = (text: string) => setQuery(text);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedItem) {
      setShowError(true);
    } else {
      const searchPath = autocompleteResultToUrl(
        category,
        selectedItem,
        radius
      );

      trackEvent('Suche', 'Suchen', 'Startseite', searchPath);
      Router.push(searchPath);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack isInline={!isMobile} pos="relative" spacing={3}>
        <BetterPlaceAutocomplete
          id={`place-autocomplete-${category.id}`}
          placeholder={category.question}
          onSelectItem={onSelectItem}
          onChangeQuery={onChangeQuery}
          category={category.frontendUrl!}
        />
        {selectedItem && selectedItem.type === 'geocode' ? (
          <RadiusSelect value={radius} onChangeValue={onChangeRadius} />
        ) : null}
        <Box flex={isMobile ? '1' : '0'}>
          <Button
            width="100%"
            variant="outline"
            colorScheme="brand"
            type="submit"
          >
            <SearchIcon marginRight={2} />
            Suchen
          </Button>
        </Box>
      </Stack>
      {showError && (
        <Alert status="error" marginTop={2}>
          <AlertDescription>
            {query !== ''
              ? 'Bitte wähle einen vorgeschlagenen Eintrag aus der Liste oder verwende Deinen aktuellen Standort.'
              : 'Du musst einen Ort angeben.'}
          </AlertDescription>
        </Alert>
      )}
    </form>
  );
};

export default HeroForm;
