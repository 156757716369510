import { Box, BoxProps, Flex, Select } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

type RadiusSelectProps = BoxProps & {
    onChangeValue: (e: ChangeEvent<HTMLSelectElement>) => void;
    value: string;
};

const RadiusSelect: React.FC<RadiusSelectProps> = ({
    value,
    onChangeValue,
    ...props
}) => {
    return (
        <Box {...props}>
            <Flex alignItems="center">
                <Box>im Umkreis von</Box>
                <Box flex={1} marginLeft={3}>
                    <Select
                        value={value}
                        name="radius"
                        onChange={onChangeValue}
                    >
                        <option value="10">10 km</option>
                        <option value="20">20 km</option>
                        <option value="30">30 km</option>
                        <option value="50">50 km</option>
                        <option value="100">100 km</option>
                        <option value="200">200 km</option>
                    </Select>
                </Box>
            </Flex>
        </Box>
    );
};

export default RadiusSelect;
