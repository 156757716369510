import {
  Box,
  Flex,
  Heading,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Img,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Container from '../../Layout/Container';
import HeroForm from './HeroForm';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import { ParagraphHeroFragmentFragment } from '../../../generated/types';
import CopyrightImage from '../../Common/CopyrightImage';
import useIsMobile from '../../../hooks/useIsMobile';
import { useStore } from '../../../store/provider';
import { observer } from 'mobx-react-lite';
import getAppPublicPath from '../../../utils/getAppPublicPath';

export interface HeroProps {
  data: ParagraphHeroFragmentFragment;
}

const Hero: React.FC<HeroProps> = ({ data }) => {
  const isMobile = useIsMobile();
  const store = useStore();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Flex
      className="PargraphHero"
      alignItems="center"
      justifyContent="center"
      height="635px"
      marginTop="-80px"
      pos="relative"
    >
      <CopyrightImage
        copyright={data.image?.copyright || ''}
        alt={data.image?.imageAlt || ''}
        title={data.image?.imageTitle || ''}
        pos="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
        bg="#000"
        opacity={0.36}
        maxW="none"
        width="100%"
        height="635px"
        objectFit="cover"
        zIndex={1}
        src={data.image?.hero?.url}
      />
      <Box
        pos="absolute"
        zIndex={-1}
        top="0"
        right="0"
        bottom="0"
        left="0"
        background="rgba(0,0,0, .85)"
      />
      <Box pos="relative" zIndex={4} color="#fff" marginTop="80px" width="100%">
        <Container>
          <Box>
            <Flex marginBottom={16} justifyContent="center">
              <Img
                maxHeight="65px"
                src={`${getAppPublicPath()}/${
                  store.useNegativeLogoVersion ? 'logo-negative' : 'logo'
                }.svg`}
              />
            </Flex>
            <Heading
              textShadow="rgba(0,0,0, .5) 0 2px 4px"
              textAlign="center"
              fontSize="3xl"
            >
              {data.title}
            </Heading>
            <Heading
              textShadow="rgba(0,0,0, .5) 0 2px 4px"
              textAlign="center"
              fontSize="2xl"
              fontWeight="400"
            >
              {data.subtitle}
            </Heading>
            <Box marginTop={12} maxW="950px" marginX="auto">
              <Box>
                <Flex
                  hidden={!isMobile}
                  alignItems="center"
                  color="#000"
                  padding={2}
                  bg="#fff"
                >
                  <Box padding={2} fontWeight="bold" whiteSpace="nowrap">
                    Was suchst Du?
                  </Box>
                  <Select
                    onChange={(e) => {
                      setTabIndex(parseInt(e.currentTarget.value, 10));
                    }}
                  >
                    {data.categories.map((categoryMap, index) => {
                      return (
                        <option key={categoryMap.id} value={index}>
                          {categoryMap.title}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
                <Tabs
                  index={tabIndex}
                  onChange={handleTabChange}
                  variant="unstyled"
                >
                  <TabList hidden={isMobile} borderBottom={0}>
                    {data.categories.map((categoryMap) => {
                      const Icon = getIconForCategoryKey(
                        categoryMap.category!.key!
                      );

                      return (
                        <Tab
                          borderTopLeftRadius="md"
                          borderTopRightRadius="md"
                          marginRight={1}
                          borderBottom={0}
                          bg="rgba(255, 255, 255, .22)"
                          _selected={{
                            color: 'textColor',
                            bg: '#fff',
                            _hover: {
                              bg: '#fff',
                            },
                          }}
                          paddingX={4}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          _hover={{
                            bg: 'rgba(255,255,255, .8)',
                            color: 'textColor',
                          }}
                          key={`tab_${categoryMap.id}`}
                        >
                          {Icon && <Icon marginRight={2} />}

                          {categoryMap.title}
                        </Tab>
                      );
                    })}
                  </TabList>
                  <TabPanels
                    bg="#fff"
                    color="#333"
                    borderBottomLeftRadius="md"
                    borderBottomRightRadius="md"
                    borderTopRightRadius={isMobile ? '0' : 'md'}
                  >
                    {data.categories.map((categoryMap) => (
                      <TabPanel key={`panel_${categoryMap.id}`}>
                        <HeroForm category={categoryMap.category!} />
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Box>
              {/* <Box
                                fontWeight="bold"
                                textShadow="rgba(0,0,0, .5) 0 2px 4px"
                                marginTop={4}
                            >
                                Über 1100 Hotels und 800 Aktivitäten vorhanden…
                            </Box> */}
            </Box>
          </Box>
        </Container>
      </Box>
    </Flex>
  );
};

export default observer(Hero);
